// ======================================================================
// Hepburn 3 | hello@hashandsalt.com
// ======================================================================

// window.$ = window.jQuery = require('jquery');

// ======================================================================
// Modules
// ======================================================================

// require any further scripts here ie:
// require('npmpackagename');
// require('./mylocalfile.js');

import Swup from "swup";
import SwupHeadPlugin from '@swup/head-plugin';
import SwupBodyClassPlugin from "@swup/body-class-plugin";
// import SwupDebugPlugin from '@swup/debug-plugin';
import MiniMasonry from "minimasonry";

import 'media-chrome';


const swup = new Swup({
  containers: ["#middle"],
  plugins: [new SwupBodyClassPlugin(), new SwupHeadPlugin()],
});

// Video Player
// function hepburnPlayer() {
//   // Select elements here
//   const video = document.getElementById("video");
//   const videoControls = document.getElementById("video-controls");
//   const playButton = document.getElementById("play");
//   const playbackIcons = document.querySelectorAll(".playback-icons use");

//   const volumeButton = document.getElementById("volume-button");
//   const volumeIcons = document.querySelectorAll(".volume-button use");
//   const volumeMute = document.querySelector('use[href="#volume-mute"]');
//   const volumeLow = document.querySelector('use[href="#volume-low"]');
//   const volumeHigh = document.querySelector('use[href="#volume-high"]');
//   const volume = document.getElementById("volume");

//   const videoContainer = document.getElementById("video-container");

//   const videoWorks = !!document.createElement("video").canPlayType;
//   if (videoWorks) {
//     video.controls = false;
//     if (videoContainer.classList.contains("videocontrols")) {
//       videoControls.classList.remove("hidden");
//     }
//   }

//   // Add functions here

//   // togglePlay toggles the playback state of the video.
//   // If the video playback is paused or ended, the video is played
//   // otherwise, the video is paused
//   function togglePlay() {
//     if (video.paused || video.ended) {
//       video.play();
//     } else {
//       video.pause();
//     }
//   }

//   // updatePlayButton updates the playback icon and tooltip
//   // depending on the playback state
//   function updatePlayButton() {
//     playbackIcons.forEach((icon) => icon.classList.toggle("hidden"));

//     if (video.paused) {
//       playButton.setAttribute("data-title", "Play (k)");
//     } else {
//       playButton.setAttribute("data-title", "Pause (k)");
//     }
//   }

//   // updateVolumeIcon updates the volume icon so that it correctly reflects
//   // the volume of the video
//   function updateVolumeIcon() {
//     volumeIcons.forEach((icon) => {
//       icon.classList.add("hidden");
//     });

//     volumeButton.setAttribute("data-title", "Mute (m)");

//     if (video.muted) {
//       volumeMute.classList.remove("hidden");
//       volumeButton.setAttribute("data-title", "Unmute (m)");
//     } else {
//       volumeHigh.classList.remove("hidden");
//     }
//   }

//   updateVolumeIcon();

//   // toggleMute mutes or unmutes the video when executed
//   // When the video is unmuted, the volume is returned to the value
//   // it was set to before the video was muted
//   function toggleMute() {
//     video.muted = !video.muted;
//     if (video.muted) {
//       video.muted = true;
//     } else {
//       video.muted = false;
//     }
//   }

//   // togglePip toggles Picture-in-Picture mode on the video
//   async function togglePip() {
//     try {
//       if (video !== document.pictureInPictureElement) {
//         pipButton.disabled = true;
//         await video.requestPictureInPicture();
//       } else {
//         await document.exitPictureInPicture();
//       }
//     } catch (error) {
//       console.error(error);
//     } finally {
//       pipButton.disabled = false;
//     }
//   }

//   // hideControls hides the video controls when not in use
//   // if the video is paused, the controls must remain visible
//   function hideControls() {
//     if (video.paused) {
//       return;
//     }

//     videoControls.classList.add("hide");
//   }

//   // showControls displays the video controls
//   function showControls() {
//     videoControls.classList.remove("hide");
//   }

//   function keyboardShortcuts(event) {
//     if (videoContainer.classList.contains("videocontrols")) {
//       const { key } = event;

//       switch (key) {
//         case "k":
//           togglePlay();
//           if (video.paused) {
//             showControls();
//           } else {
//             setTimeout(() => {
//               hideControls();
//             }, 2000);
//           }
//           break;
//         case "m":
//           toggleMute();
//           break;
//       }
//     }
//   }

//   // Add eventlisteners here
//   playButton.addEventListener("click", togglePlay);
//   video.addEventListener("play", updatePlayButton);
//   video.addEventListener("pause", updatePlayButton);
//   video.addEventListener("volumechange", updateVolumeIcon);
//   video.addEventListener("click", togglePlay);
//   volumeButton.addEventListener("click", toggleMute);
//   video.addEventListener("mouseenter", showControls);
//   video.addEventListener("mouseleave", hideControls);
//   videoControls.addEventListener("mouseenter", showControls);
//   videoControls.addEventListener("mouseleave", hideControls);
//   document.addEventListener("keyup", keyboardShortcuts);
// }

// Lazy Loading
function lazy() {
  let lozad = require("lozad");
  const observer = lozad();
  observer.observe();
}

var hamburger = document.querySelector(".menu-butt");
var menu = document.querySelector(".site-menu");
var bodyclasses = document.body;

function menuTogggle() {
  // If active...
  if (menu.classList.contains("site-menu-active")) {
    // Hide
    menu.classList.remove("site-menu-active");
    menu.setAttribute("aria-hidden", "true");
    hamburger.classList.remove("is-active");
    hamburger.setAttribute("aria-expanded", "false");

    bodyclasses.classList.remove("no-scroll");
    bodyclasses.classList.remove("menu-open");
  } else {
    // Show
    menu.classList.add("site-menu-active");
    menu.setAttribute("aria-hidden", "false");
    hamburger.classList.add("is-active");
    hamburger.setAttribute("aria-expanded", "true");

    bodyclasses.classList.add("no-scroll");
    bodyclasses.classList.add("menu-open");

    // Set focus on first link
    // menu.children[0].children[0].children[0].focus();
  }
}

if (hamburger) {
  hamburger.addEventListener(
    "click",
    function () {
      menuTogggle();
    },
    false
  );
}

var menulinks = document.querySelectorAll(".site-menu a");

if (menulinks) {
  menulinks.forEach((item) => {
    item.addEventListener("click", (event) => {
      menuTogggle();
    });
  });
}

function init() {
  if (document.querySelector(".masonary")) {
    var masonry = new MiniMasonry({
      container: ".masonary",
      baseWidth: 300,
    });
  }

  lazy();
}

init();

swup.hooks.on("page:view", () => {
  init();
});
